import {
  PaymentElement,
  LinkAuthenticationElement,
} from "@stripe/react-stripe-js";
import React, { useState, useEffect } from "react";

import { loadStripe } from "@stripe/stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import countriesList from "../country.json";
import Loader from "../Loader";

import { validateCoupon } from "../../services/paymentService";

import {
  userInfo,
  getMembershipLevel,
  subscribefree,
  getSaleSelectedEvent,
  startSubscription,
  getSaleSelectedCourse,
  startPurchaseCourse,
  startPurchaseEvent,
} from "../../helper/service";

import secureCheckout from "../../images/checkout/secure-checkout.png";
import { set } from "date-fns";

const TaxRate = 0.1;

export default function CheckoutPayment(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    postalCode: "",
    streetAddress: "",
    country: "Australia",
  });
  const [isChecked, setIsChecked] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [brandColor, setBrandColor] = useState("");
  const [plan, setPlan] = useState({});
  const [isMonthly, setIsMonthly] = useState(true);
  const [orderPrice, setOrderPrice] = useState(0.0);
  const [orderTax, setOrderTax] = useState(0.0);
  const [orderDiscount, setOrderDiscount] = useState(0.0);
  const [discountDisplayText, setDiscountDisplayText] = useState("");
  const [orderTotal, setOrderTotal] = useState(0.0);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    setIsLoading(true);
    if (props.subscriptionId) {
      var isMonthlyPayment = props.subscriptionFrequency === "monthly";
      setIsMonthly(isMonthlyPayment);
      setBrandColor(localStorage.getItem("subadminBrandColor"));

      let subsId = props.subscriptionId;
      getMembershipLevel(subsId).then((subs) => {
        let discount = 0;
        if (subs?.result?.validSubs) {
          var subscription = subs?.result?.validSubs;
          if (props.coupon) {
            let couponCode = props.coupon;
            let itemType = "subscriptions";
            validateCoupon(couponCode,itemType,subsId)
              .then((coupon) => {
                if (coupon.result) {
                  discount = coupon.result.discount;
                  setSubsDetails(subscription, discount);
                  setDiscountDisplayText(coupon.result.displayText);
                }
              })
              .catch((error) => {
                console.log("error while validating coupon", error);
              });
          }else{
            discount = 0;
            setSubsDetails(subscription, discount);
          }
          }
        });
    } else if (props.courseId) {
      getSaleSelectedCourse(props.courseId).then((course) => {
        let discount = 0;
        let selectedCourse = course.result[0];

        if (props.coupon) {
          let couponCode = props.coupon;
          let itemType = "courses"
          validateCoupon(couponCode,itemType,props.courseId)
            .then((coupon) => {
              if (coupon.result) {
                discount = coupon.result.discount;
                setCourseDetails(selectedCourse, discount);
                setDiscountDisplayText(coupon.result.displayText);
              }
            })
            .catch((error) => {
              console.log("error while validating coupon", error);
            });
        } else {
          discount = 0;
          setCourseDetails(selectedCourse, discount);
        }
      });
    } else if (props.eventId) {
      getSaleSelectedEvent(props.eventId).then((event) => {
        let selectedEvent = event.result;
        let discount = 0;
        if (props.coupon) {
          let couponCode = props.coupon;
          let itemType = "events"
          validateCoupon(couponCode,itemType,props.eventId)
            .then((coupon) => {
              if (coupon.result) {
                discount = coupon.result.discount;
                setEventDetails(selectedEvent, discount);
                setDiscountDisplayText(coupon.result.displayText);
              }
            })
            .catch((error) => {
              console.log("error while validating coupon", error);
            });
        } else {
          discount = 0;
          setEventDetails(selectedEvent, discount);
        }

      });
    }
  };

  const setCourseDetails = (selectedCourse, discount) => {
    let singleCourse = {
      tier: selectedCourse.title,
      description: selectedCourse.shortDescription,
    };
    var orderPrice = Math.round(selectedCourse.amount * 100) / 100;
    var discountRate = discount / 100;
    var discountAmount = orderPrice * discountRate;
    var discountedPrice = (orderPrice - discountAmount) / 1.1;
    var tax = discountedPrice * TaxRate;
    setPlan(singleCourse);
    setOrderPrice(discountedPrice.toFixed(2));
    setOrderTax(tax.toFixed(2));
    setOrderDiscount(discountAmount.toFixed(2));
    setOrderTotal((discountedPrice + tax).toFixed(2));
    setIsLoading(false);
  };
  const setSubsDetails = (selectedSubs, discount) => {
    var isMonthlyPayment = props.subscriptionFrequency === "monthly";
    setPlan(selectedSubs);
    var orderMonthlyPrice =
      Math.round(selectedSubs.amountMonthly * 100) / 100;
    var orderYearlyPrice =
      Math.round(selectedSubs.amountYearly * 100) / 100;

    var originalPrice = isMonthlyPayment
      ? orderMonthlyPrice
      : orderYearlyPrice;

    var discountRate = discount / 100;
    var discountAmount = originalPrice * discountRate;
    var discountedPrice = (originalPrice - discountAmount) / 1.1;
    var tax = discountedPrice * TaxRate;
    setOrderPrice(discountedPrice.toFixed(2));
    setOrderTax(tax.toFixed(2));
    setOrderDiscount(discountAmount.toFixed(2));
    setOrderTotal((discountedPrice + tax).toFixed(2));
    setIsLoading(false);


  };
  const setEventDetails = (selectedEvent, discount) => {
    let singleEvent = {
      tier: selectedEvent.name,
      description: selectedEvent.description.replace(/<[^>]*>/g, ""),
    };
    var orderPrice = Math.round(selectedEvent.eventAmount * 100) / 100;
    var discountRate = discount / 100;
    var discountAmount = orderPrice * discountRate;
    var discountedPrice = (orderPrice - discountAmount) / 1.1;
    var tax = discountedPrice * TaxRate;
    setPlan(singleEvent);
    setOrderPrice(discountedPrice.toFixed(2));
    setOrderTax(tax.toFixed(2));
    setOrderDiscount(discountAmount.toFixed(2));
    setOrderTotal((discountedPrice + tax).toFixed(2));
    setIsLoading(false);
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const validate = () => {
    let errors = {};

    if (!isChecked) {
      errors.isChecked = "* Please accept terms & conditions";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let data = {};
    if (props.subscriptionId) {
      data = {
        fullName:
          props.memberDetails.firstName + " " + props.memberDetails.lastName,
        email: props.memberDetails.email?.trim(),
        phone: props.memberDetails.phone?.trim(),
        country: props.memberDetails.country,
        streetAddress: props.memberDetails.streetAddress,
        postalCode: props.memberDetails.postalCode,
        coupon: props.coupon,
        subscriptionId: props.subscriptionId,
        subscriptionName: plan.tier,
        subscriptionType: props.subscriptionFrequency,
      };
    } else if (props.courseId) {
      data = {
        fullName:
          props.memberDetails.firstName + " " + props.memberDetails.lastName,
        email: props.memberDetails.email?.trim(),
        phone: props.memberDetails.phone?.trim(),
        country: props.memberDetails.country,
        streetAddress: props.memberDetails.streetAddress,
        postalCode: props.memberDetails.postalCode,
        courseId: props.courseId,
        courseName: plan.tier,
        coupon: props.coupon,
        token: localStorage.getItem("token"),
        subadminId: localStorage.getItem("subadminId"),
      };
    } else if (props.eventId) {
      data = {
        fullName:
          props.memberDetails.firstName + " " + props.memberDetails.lastName,
        email: props.memberDetails.email?.trim(),
        phone: props.memberDetails.phone?.trim(),
        country: props.memberDetails.country,
        streetAddress: props.memberDetails.streetAddress,
        postalCode: props.memberDetails.postalCode,
        eventId: props.eventId,
        eventName: plan.tier,
        coupon: props.coupon,
        token: localStorage.getItem("token"),
        subadminId: localStorage.getItem("subadminId"),
      };
    }

    const dataIsValid = validate();

    if (dataIsValid) {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setIsLoading(true);

      // NEEDED for capturing before creating intent
      // // Trigger form validation and wallet collection
      // const {error: submitError} = await elements.submit();
      // if (submitError) {
      //   setMessage(submitError.message);
      //   return;
      // }

      // var result = await createMemberPayment(props.subscriptionId, props.subscriptionFrequency);
      // console.log(result, 'strip result');
      // const clientSecret = result.clientSecret;

      //var subResult = await subscribefree(props.subscriptionId);
      const subadminId = localStorage.getItem("subadminId");
      data.subadminId = subadminId;
      var subResult;
      var returnUrl;
      if (props.subscriptionId) {
        returnUrl = `${window.location.origin}/complete-subscription?subscriptionId=${props.subscriptionId}&flow=registration`;
        subResult = await startSubscription(data);
      } else if (props.courseId) {
        returnUrl = `${window.location.origin}/complete-course?courseId=${props.courseId}&flow=registration`;
        subResult = await startPurchaseCourse(data);
      } else if (props.eventId) {
        returnUrl = `${window.location.origin}/complete-event?eventId=${props.eventId}&flow=registration`;
        subResult = await startPurchaseEvent(data);
      }

      if (subResult) {
        const { error } = await stripe.confirmPayment({
          elements,
          //clientSecret,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: returnUrl,
          },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
          //await initialize();
        } else {
          setMessage("An unexpected error occured.");
        }
      }

      setIsLoading(false);
    }
  };

  const headingStyle = {
    color: brandColor,
    fontWeight: "bold",
    fonnSize: "1.5rem",
  };

  const inputBoxStyle = {
    backgroundColor: "white",
    borderColor: "#D2D2D2",
    height: "2.5em",
  };

  const priceSectionStyle = {
    fontWeight: "600",
    fonnSize: "1.5rem",
  };
  const errorMessageStyle = {
    color: "red",
    fontSize: "0.7rem",
  };

  const lineStyle = {
    width: "55%",
    marginLeft: "5%",
    marginRight: "20%",
    marginTop: ".3em",
    marginBottom: ".3em",
    height: "1px",
    backgroundColor: "#D2D2D2",
  };

  return (
    <>
      {isLoading ? <Loader /> : <></>}
      <Form>
        <Row>
          <Col>
            <Form.Group>
              <Form.Group>
                <PaymentElement id="payment-element" />
                {/* Show any error or success messages */}
                {message && <div id="payment-message">{message}</div>}
              </Form.Group>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  id="checkboxId"
                  label="I have read and agree to the website terms and conditions"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  required
                />
                <span style={errorMessageStyle}>{formErrors.isChecked}</span>
              </Form.Group>
              <Form.Group>
                <Form.Text>
                  Your personal data will be used to process your order, support
                  your experience throughout this website, and for other
                  purposes described in our{" "}
                  <a target="blank" href="/privacy-policy">
                    privacy policy
                  </a>
                  .
                </Form.Text>
              </Form.Group>
              <Form.Group className="my-3">
                <Button
                  style={{ backgroundColor: brandColor, border: "none" }}
                  onClick={handleSubmit}
                  type="submit"
                >
                  Place Order
                </Button>
              </Form.Group>
              <Form.Group>
                <img src={secureCheckout} />
              </Form.Group>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="column2">
              {props.subscriptionId && (
                <Form.Label style={headingStyle}>PLAN DETAILS</Form.Label>
              )}
              {(props.courseId || props.eventId) && (
                <Form.Label style={headingStyle}>PRODUCT DETAILS</Form.Label>
              )}
              <Form.Text>
                <div className="d-flex align-items-center">
                  <strong>{plan.tier}</strong>
                </div>
              </Form.Text>
              <Form.Text>
                <div className="d-flex align-items-center">
                  <span>{plan.description}</span>
                </div>
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="column3">
              <Form.Label style={headingStyle}>YOUR ORDER</Form.Label>
              <Form.Group>
                <Row>
                  <Col>
                    <Form.Text style={priceSectionStyle}>Plan</Form.Text>
                  </Col>

                  <Col>
                    <Form.Text style={priceSectionStyle}>Total</Form.Text>
                  </Col>
                </Row>
                <div style={lineStyle}></div>
                <Row>
                  <Col>
                    {props.courseId && <Form.Text>Course Price</Form.Text>}
                    {props.eventId && <Form.Text>Event Price</Form.Text>}
                    {props.subscriptionId && (
                      <Form.Text>
                        Business {isMonthly ? "(Monthly)" : "(Yearly)"}
                      </Form.Text>
                    )}
                  </Col>
                  <Col>
                    <Form.Text> ${orderPrice}</Form.Text>
                  </Col>
                </Row>
                <div style={lineStyle}></div>
                <Row>
                  <Col>
                    <Form.Text style={priceSectionStyle}>Taxes</Form.Text>
                  </Col>
                  <Col>
                    <Form.Text> ${orderTax}</Form.Text>
                  </Col>
                </Row>
                <div style={lineStyle}></div>
                <Row>
                  <Col>
                    <Form.Text style={priceSectionStyle}>Discount</Form.Text>
                  </Col>
                  <Col>
                    <Form.Text> ${orderDiscount}</Form.Text>
                  </Col>
                </Row>
                <div style={lineStyle}></div>
                <Row>
                  <Col>
                    <Form.Text style={priceSectionStyle}>Total</Form.Text>
                  </Col>
                  <Col>
                    <Form.Text style={priceSectionStyle}>
                      {" "}
                      ${orderTotal}
                    </Form.Text>
                    <Form.Text style={{ "font-style": "italic" }}>
                      Currency in AUD
                    </Form.Text>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {discountDisplayText && (
                      <Form.Text style={{ "font-style": "italic" }}>
                        {discountDisplayText}
                      </Form.Text>
                    )}
                  </Col>
                </Row>
              </Form.Group>
            </Form.Group>
            {/* <Form.Group>
                                    <Form.Label type="text" placeholder="Input 2" style={headingStyle}>HAVE A COUPEN</Form.Label>
                                    <Row>
                                        <Col>
                                            <Form.Control type='text' style={{ height: "2.6em", witdth: "10em", backgroundColor: "white", borderColor: "#D2D2D2" }} />
                                        </Col>
                                        <Col>
                                            <Button style={{ backgroundColor: "#12AEB4", border: "none" }}>Apply</Button>
                                        </Col>
                                    </Row>
                                </Form.Group> */}
          </Col>
        </Row>
      </Form>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Spinner, Row, Col } from "react-bootstrap";
import Header from "../common/Header";
import Loader from "../Loader";
import searchDark from "../../images/enduser/search-dark.svg";
import { useQuery } from "@tanstack/react-query";
import {
  getContentfolders,
  getContentBySubs,
  subadminAllDataCount,
} from "../../helper/service";
import {
  IFolder,
  IgetContentBySubsResponse,
} from "../../interfaces/interfaces";
import FolderCard from "../common/FolderCard";
import ContentList from "./ContentList";
import { useSearchQuery } from "../../contexts/SearchContext";
// import file from '../../images/admin/file.svg';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import FoldersListModal from "./FoldersListModal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Mousewheel } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import ModuleNotAvailable from "../common/ModuleNotAvailable";

function ContentLibraryPage() {
  const [pageLoading, setPageLoading] = React.useState(false);
  const [activeView, setActiveView] = useState<string>("list");
  const [brandColor, setBrandColor] = useState<string>("");
  const [visibleFolders, setVisibleFolders] = useState(10);

  useEffect(() => {
    const color = localStorage.getItem("subadminBrandColor") || "#919294";
    setBrandColor(color);
  }, []);
  const getContentFolderQuery = useQuery({
    queryKey: ["getContentFolder"],
    queryFn: () => {
      return getContentfolders();
    },
  });

  const getContentBySubsQuery = useQuery<IgetContentBySubsResponse>({
    queryKey: ["getContentBySubs"],
    queryFn: () => {
      let subadminId = localStorage.getItem("subadminId");
      return getContentBySubs(subadminId);
    },
  });
  const [searrchQuery, setSearchQuery] = useSearchQuery();
  const [showFoldersListModal, setShowFoldersListModal] = React.useState(false);
  const [selectedFolder, setSelectedFolder] = React.useState<IFolder | null>(
    null
  );
  const [accessAllowed, setAccessAllowed] = React.useState(true);
  const [instructorEmail, setInstructorEmail] = React.useState("");

  useEffect(() => {
    subadminAllDataCount().then((data) => {
      if (data?.result?.partnerData) {
        if (
          data.result.partnerData.foldersLength >
            data.result.partnerData.currentFoldersAllowed ||
          data.result.partnerData.membersLength >
            data.result.partnerData.currentMembersAllowed ||
          data.result.partnerData.memberALLength >
            data.result.partnerData.currentSubscriptionAllowed
        ) {
          setAccessAllowed(false);
          setInstructorEmail(data.result.partnerData.instructorEmail);
        }
      }
    });
  }, []);

  const handleLoadMore = () => {
    setVisibleFolders((prev) => prev + 5);
  };

  const contentFoldersIsAvailable =
    getContentFolderQuery.isSuccess &&
    getContentFolderQuery.data?.result &&
    Array.isArray(getContentFolderQuery.data?.result) &&
    getContentFolderQuery.data?.result.length > 0;

  var folderCount = Array.isArray(getContentFolderQuery.data?.result)
  ? getContentFolderQuery.data?.result.length
  : 0;

  const contentIsAvailable =
    getContentBySubsQuery.isSuccess &&
    getContentBySubsQuery.data?.result &&
    Array.isArray(getContentBySubsQuery.data?.result) &&
    getContentBySubsQuery.data?.result.length > 0;

  return (
    <div className="wrapper">
      {pageLoading ? <Loader /> : <></>}
      <Header />

      <div className="content-wrapper video-library admin-courses">
        <section className="">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8">
                  <h2 className="title">
                    Content Library
                    {/* <a href="#">Return to Multimedia</a> */}
                  </h2>
                </div>
                <div className="col-lg-4">
                  <div className="search-course">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Content Library"
                      name="search"
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <span className="search-icon">
                      <img src={searchDark} alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="row">
          <div className="d-flex justify-content-end">
            <button
              className="icon-button mr-2"
              aria-label="List View"
              onClick={() => setActiveView("list")}
              style={{
                color: activeView === "list" ? brandColor : "#919294",
              }}
            >
              <i className="fas fa-list fa-2x"></i>
            </button>
            <button
              className="mr-2 icon-button"
              aria-label="Grid View"
              onClick={() => setActiveView("grid")}
              style={{
                color: activeView === "grid" ? brandColor : "#919294",
              }}
            >
              <i className="fas fa-th-large fa-2x"></i>
            </button>
          </div>
        </div>

        {accessAllowed ? (
          <>
            <section className="courses-folders">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="d-flex align-items-center border-line">
                      <h2 className="title">Folders</h2>
                    </div>
                  </div>
                </div>
                {activeView === "list" ? (
                  <Swiper
                    spaceBetween={10}
                    slidesPerView={6}
                    modules={[Navigation, Mousewheel]}
                    navigation={true}
                    breakpoints={{
                      400: {
                        width: 400,
                        slidesPerView: 2,
                      },
                      768: {
                        width: 768,
                        slidesPerView: 3,
                      },
                    }}
                  >
                    {getContentFolderQuery.isLoading ? (
                      <Spinner animation="border" />
                    ) : (
                      <></>
                    )}
                    {contentFoldersIsAvailable &&
                      getContentFolderQuery.data?.result
                        ?.filter((folder: IFolder) => {
                          return folder?.title
                            ?.toLowerCase()
                            .includes(searrchQuery.toLowerCase());
                        })
                        ?.map((folder: IFolder) => {
                          let totalcontent =
                            typeof folder.totalcontent === "number" &&
                            folder.totalcontent > 0;
                          let totalsubfolder =
                            typeof folder.totalsubfolder === "number" &&
                            folder.totalsubfolder > 0;
                          let folderSchedule = folder?.schedule;
                          let folderScheduleDate = "";
                          let formattedCurrentDateTime = "";
                          if (folderSchedule) {
                            var originalDate = new Date(folderSchedule);
                            var selectedTimeZone =
                              localStorage.getItem("websiteTimeZone");
                            if (selectedTimeZone) {
                              folderScheduleDate = originalDate.toLocaleString(
                                "en-US",
                                {
                                  timeZone: selectedTimeZone,
                                }
                              );

                              var currentDateTime = new Date();
                              formattedCurrentDateTime =
                                currentDateTime.toLocaleString("en-US", {
                                  timeZone: selectedTimeZone,
                                });
                            }
                          }

                          if (
                            (!folderSchedule ||
                              new Date(folderScheduleDate) <
                                new Date(formattedCurrentDateTime)) &&
                            (totalcontent || totalsubfolder)
                          ) {
                            return (
                              <SwiperSlide className="discover-slider courses-slider owl-carousel owl-theme">
                                <FolderCard
                                  folder={folder}
                                  setSelectedFolder={setSelectedFolder}
                                  setShowFoldersListModal={
                                    setShowFoldersListModal
                                  }
                                />
                              </SwiperSlide>
                            );
                          }
                        })}
                  </Swiper>
                ) : (
                  <div className="row">
                    {getContentFolderQuery.isLoading ? (
                      <Spinner animation="border" />
                    ) : (
                      <></>
                    )}
                    {contentFoldersIsAvailable &&
                      getContentFolderQuery.data?.result
                        ?.filter((folder: IFolder) => {
                          return folder?.title
                            ?.toLowerCase()
                            .includes(searrchQuery.toLowerCase());
                        })
                        ?.map((folder: IFolder) => {
                          let totalcontent =
                            typeof folder.totalcontent === "number" &&
                            folder.totalcontent > 0;
                          let totalsubfolder =
                            typeof folder.totalsubfolder === "number" &&
                            folder.totalsubfolder > 0;
                          let folderSchedule = folder?.schedule;
                          let folderScheduleDate = "";
                          let formattedCurrentDateTime = "";
                          if (folderSchedule) {
                            var originalDate = new Date(folderSchedule);
                            var selectedTimeZone =
                              localStorage.getItem("websiteTimeZone");
                            if (selectedTimeZone) {
                              folderScheduleDate = originalDate.toLocaleString(
                                "en-US",
                                {
                                  timeZone: selectedTimeZone,
                                }
                              );

                              var currentDateTime = new Date();
                              formattedCurrentDateTime =
                                currentDateTime.toLocaleString("en-US", {
                                  timeZone: selectedTimeZone,
                                });
                            }
                          }

                          if (
                            (!folderSchedule ||
                              new Date(folderScheduleDate) <
                                new Date(formattedCurrentDateTime)) &&
                            (totalcontent || totalsubfolder)
                          ) {
                            return (
                              <div
                                className=""
                                style={{ flex: "0 0 20%", maxWidth: "20%" }}
                              >
                                <FolderCard
                                  folder={folder}
                                  setSelectedFolder={setSelectedFolder}
                                  setShowFoldersListModal={
                                    setShowFoldersListModal
                                  }
                                />
                              </div>
                            );
                          }
                        })}
                  </div>
                )}
              </div>
            </section>
            {contentFoldersIsAvailable &&
              visibleFolders <= folderCount &&
              activeView === "grid" && (
                <div className="flex d-flex justify-content-center mt-3">
                  <button
                    className="btn-load-more"
                    style={{ backgroundColor: brandColor }}
                    onClick={handleLoadMore}
                  >
                    Load More
                  </button>
                </div>
              )}
            <section className="videos">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="d-flex align-items-center border-line">
                      <h2 className="title">Content</h2>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <ul className="nav nav-tabs" id="videoTab" role="tablist">
                      {/* <li className="nav-item" role="presentation">
                <a className="nav-link active" id="youFav-tab" data-toggle="tab" href="#youFav" role="tab" aria-controls="youFav" aria-selected="true">Your Favourites</a>
              </li> */}
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link active"
                          id="latestCont-tab"
                          data-toggle="tab"
                          href="#latestCont"
                          role="tab"
                          aria-controls="latestCont"
                          aria-selected="false"
                        >
                          Latest Content
                        </a>
                      </li>
                      {/* <li className="nav-item ml-auto" role="presentation">
                    <a className="nav-link" id="viewAll-tab" data-toggle="tab" href="#" role="tab">
                      View all <i className="fas fa-angle-right" />
                    </a>
                  </li> */}
                    </ul>
                    <div className="tab-content scroll" id="videoTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="youFav"
                        role="tabpanel"
                        aria-labelledby="youFav-tab"
                      >
                        <ContentList />
                      </div>
                      <div
                        className="tab-pane fade"
                        id="latestCont"
                        role="tabpanel"
                        aria-labelledby="latestCont-tab"
                      >
                        <ContentList />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <ModuleNotAvailable
            title="Folders are not available"
            email={instructorEmail}
          />
        )}
      </div>

      {/* Folders List Modal */}
      {selectedFolder && (
        <FoldersListModal
          open={showFoldersListModal}
          parentFolder={selectedFolder}
          setParentFolder={setSelectedFolder}
          setShowFoldersListModal={setShowFoldersListModal}
        />
      )}
    </div>
  );
}

export default ContentLibraryPage;

import { useState } from "react";
import { ICourse } from "../../interfaces/interfaces";
import { Link } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { enrollCourse } from "../../helper/service";
import SuccessPopup from "../Setting/SuccessPopup";
import { commonUtil } from "../../helper/CommonUtils";
import { Button } from "react-bootstrap";

let brandColor = localStorage.getItem("subadminBrandColor");

const CourseCard = ({
  course,
  showEnrollBtn = false,
  showLink = true,
}: {
  course: ICourse;
  showEnrollBtn?: boolean;
  showLink?: boolean;
}) => {
  const queryClient = useQueryClient();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const enrollCourseMutation = useMutation({
    mutationKey: ["enrollCourse", course._id],
    mutationFn: () => {
      return enrollCourse(course._id);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["getEnrolledCourses"] });
      await queryClient.invalidateQueries({ queryKey: ["getAllCourses"] });
      setShowSuccessPopup(true);
    },
    onError: (error) => {
      console.log("🚀 ~ file: CourseCard.tsx:44 ~ onError ~ error", error);
      alert("Something went wrong! Failed to enroll course");
    },
  });

  const handleEnroll = () => {
    const confirm = window.confirm("Are you sure you want to enroll?");
    if (!confirm) return;
    enrollCourseMutation.mutate();
  };
  const selectCourse = (course) => {
    let subsId=localStorage.getItem("subscriptionId");
    if (course?.acceptIndPayment && !course?.memberAccessLevel?.includes(subsId)) {
      let url = `/coursecheckout/${course?._id}`;
      commonUtil.redirect(url);
    }
    else {
      enrollCourseMutation.mutate();
    }
  };

  const numberOfStudents = course?.students?.length || 0;
  const isEnrolled =
    course?.students?.includes(localStorage.getItem("user_id") || "") || false;
  const enrollmentPaused = course?.enrollment === "Paused" || false;

  return (
    <div>
      <Link to={`/course-module/${course._id}`}>
        <div className="main-img w-100">
          <img
            src={course.courseImage}
            className="img-over w-100"
            alt={course.title}
          />
        </div>
        <h5 className="slide-title">{course.title}</h5>
      </Link>

      <p className="slide-text">
        <span>
          {course.tLesson}{" "}
          {typeof course?.tLesson === "number" && course?.tLesson > 1
            ? "Lessons"
            : "Lesson"}
        </span>
      </p>
      {showSuccessPopup ? (
        <SuccessPopup message="You have been enrolled succesfully and added to social wall for the course/challenge." />
      ) : (
        <></>
      )}

      {!course?.acceptIndPayment &&
        showEnrollBtn &&
        !isEnrolled &&
        !enrollmentPaused && (
          <div className="text-center cursor-pointer">
            {enrollCourseMutation.isPending ? (
              <span>Enrolling...</span>
            ) : (
              <Button
                className="purchase-button"
                style={{
                  backgroundColor: `${brandColor !== null ? brandColor : ""}`,
                  minWidth: "150px",
                }}
                onClick={handleEnroll}
              >
                <span>Enroll</span>
              </Button>
            )}
          </div>
        )}

      {course?.acceptIndPayment && (
        // <div className="float-right d-flex cursor-pointer">
        //   <span onClick={() => selectCourse(course._id)}>Purchase at ${course.amount}</span>
        // </div>
        <div className="text-center">
          <Button
            className="purchase-button"
            style={{
              backgroundColor: `${brandColor !== null ? brandColor : ""}`,
              minWidth: "150px",
            }}
            onClick={() => selectCourse(course)}
          >
            <span>Purchase at {`$${course.amount}`}</span>
          </Button>
        </div>
      )}

      <div className="clearfix"></div>
    </div>
  );
};

export default CourseCard;
